
.student-breadcrumb {
    font-size: 14px;
}

.operationPromotion-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;

    .title {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        margin: 20px 0;
        line-height: 1;
    }

    .promotion-table {
        min-height: calc(100vh - 195px);
        width: 100%;

        ::v-deep .el-tabs {
            min-height: calc(100vh - 215px);
            background-color: #fff;

            .el-tabs__content {
                min-height: calc(100vh - 299px);

                .el-tab-pane {
                    min-height: calc(100vh - 299px);
                }
            }

            .el-tabs__header {
                padding: 20px;

                .el-tabs__nav-wrap::after {
                    height: 0px;
                }

                .el-tabs__item {
                    font-size: 20px;
                    font-weight: 400;
                    color: #333333;
                }

                .el-tabs__item {
                    line-height: 29px;
                }

                .el-tabs__nav .is-active {
                    color: #FD4446;
                }

                .el-tabs__active-bar {
                    background-color: #FD4446;
                }
            }
        }
    }
}

.promotion-txt {
    display: flex;
    justify-content: space-between;
    color: #333333;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    margin: 20px 0;

    span:nth-child(2) {
        font-size: 18px;
        color: #FD4446;
        font-weight: 500;
    }

    b {
        font-size: 12px;
        margin: 0 2px;
        font-weight: 500;
    }
}
