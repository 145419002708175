
.booth-ad-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px;
    min-height: calc(100vh - 267px);
}
.search-keywords {
    display: flex;
    align-items: center;
    .el-input {
        flex: 1;
        width: 1%;
        margin-left: 10px;
    }
}
