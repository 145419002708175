
.booth-ad-content {
    /*margin: 0 20px;*/
    .header {
        margin: 0 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #343434;
    }

    .area-header {
        display: flex;
        align-items: center;
        margin: 20px;

        .focus-text {
            font-size: 14px;
            color: #343434;
            margin-left: 13px;
            font-weight: 400;

            .focus-city {
                font-size: 14px;
                color: #FF0000;
            }
        }
    }

    .item-title {
        font-size: 18px;
        position: relative;
        margin-left: 15px;

        &:before {
            content: '';
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            position: absolute;
            left: -13px;
            top: 3px;
        }
    }

    .focus-text {
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 10px;

        .chat-content {
            margin: 0 10px;

            .red-text {
                color: #FF0000;
            }
        }

        .angleLeft {
            position: absolute;
            border: 6px solid transparent;
            top: 18px;
            margin-top: -8px;
            left: -14px;
            border-right: 8px solid #ededed;
        }

        .angleContent {
            position: absolute;
            border: 4px solid transparent;
            top: 50%;
            margin-top: -4px;
            z-index: 99;
            left: -1.5px;
            border-right: 6px solid #ffffff;
        }
    }

    .chart-tab {
        margin: 20px 20px 15px;
        display: flex;
        flex-direction: column;

        .data-content {
            height: 330px;
            margin-top: 30px;
        }

        // 多选框的颜色
        ::v-deep .el-checkbox-group {
            .el-checkbox {
                .el-checkbox__input {
                    .el-checkbox__inner {
                        &:hover {
                            border-color: #0083FF;
                        }
                    }

                    &.is-focus {
                        .el-checkbox__inner {
                            border-color: #0083FF;
                        }
                    }
                }

                &.is-checked {
                    .el-checkbox__input {
                        &.is-checked {
                            .el-checkbox__inner {
                                background-color: #0083FF;
                                border-color: #0083FF;
                            }
                        }

                        &:hover {
                            border-color: #0083FF;
                        }
                    }

                    .el-checkbox__label {
                        color: #343434;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .cut-off {
        width: 100%;
        height: 20px;
        background: #F7F9FB;
    }

    .area-table {
        margin: 0 20px;

        ::v-deep .el-table {
            .el-table__body-wrapper {
                .el-table__row {
                    .ranking {
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        font-weight: 500;
                        background-size: cover;
                        display: inline-block;
                    }

                    &:first-child {
                        .ranking {
                            background: url("../../../assets/images/student/num-01.png") no-repeat center;
                        }
                    }

                    &:nth-of-type(2) {
                        .ranking {
                            background: url("../../../assets/images/student/num-02.png") no-repeat center;
                        }
                    }

                    &:nth-of-type(3) {
                        .ranking {
                            background: url("../../../assets/images/student/num-03.png") no-repeat center;
                        }
                    }
                }
            }
        }
    }
}

.area-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .percentage {
        width: 48px;
        text-align: right;
    }

    ::v-deep .el-progress {
        width: 1%;
        flex: 1;
        padding: 0 10px;
    }
}
